<template>
  <div>
    <form-render class="fieldQuoter" :fields="fields" :form="form" :key="formEnviaTrackMobile">
      <template #buttons>
        <b-button variant="warning" v-b-tooltip.hover title="Escanear código de barras" class="ml-1 mt-1" @click="startScannerQuagga">
          <feather-icon icon="SearchIcon" /> Escanear código de barras con Quagga
        </b-button>
        <b-button variant="warning" v-b-tooltip.hover title="Escanear código de barras" class="ml-1 mt-1" @click="initScanner">
          <feather-icon icon="SearchIcon" /> Escanear código de barras con Zxing
        </b-button>

        <input type="file" @change="uploadFiles" accept="image/*" hidden multiple capture="camera" />

        <div class="mb-6 grid grid-cols-1">
          <label class="block text-gray-700 text-sm mb-2"> Adjuntar prueba </label>
          <div class="items-center justify-center bg-grey-lighter rounded-lg border-2 border-blue px-4 py-6">
            <label v-if="files.length === 0" class="w-full flex flex-col items-center bg-white text-blue tracking-wide cursor-pointer hover:bg-blue">
              <span><i class="fa fa-cloud-upload fa-3x text-gray-700" aria-hidden="true"></i></span>

              <span class="mt-2 text-gray-700 leading-normal">Subir foto|archivo</span>
              <input type="file" @change="uploadFiles" accept=".png, .jpg, .jpeg" hidden multiple />
            </label>

            <div v-if="files.length !== 0" class="grid gap-2">
              <div v-for="(file,index) in files" :key="file.name" class="flex justify-between bg-gray-200 px-5 py-2">
                <label class="text-sm text-blue-700 font-medium">
                  {{file.name}} <span class="text-gray-600">({{(file.size/1024).toFixed(2)}}k)</span>
                </label>
                <span class="cursor-pointer" @click="deleteFile(index)"
                  ><i class="fa fa-close flex-none fill-current text-gray-600 h-3 w-3" aria-hidden="true"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </form-render>

    <video ref="videoEvidence" class="video" autoplay></video>
    <canvas ref="canvas" style="display: none;"></canvas>
    <button @click="initCamera" :disabled="photos.length > maxPhotos">Abrir camara</button>
    <button @click="capturePhoto" :disabled="photos.length > maxPhotos">Tomar Foto</button>
    <div v-for="(photo, index) in photos" :key="index">
      <img :src="photo" />
    </div>

    <div id="scanner-container" v-if="isScanning"></div>
    <p class="text-danger">camara seleccioanda: {{ labelCamera }}</p>
    <div v-if="isScanningZxing" class="scanner-container">
      <video ref="video" class="video"></video>
      <div class="controls">
        <button @click="toggleCamera" class="toggle-camera-button">
          <i class="fas fa-sync-alt"></i>
        </button>
        <button @click="stopScannerZxing" class="stop-scanner-button">
          <i class="fas fa-stop"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Quagga from 'quagga'
import { BrowserMultiFormatReader, NotFoundException, BarcodeFormat, DecodeHintType } from '@zxing/library'

export default {
  name: 'EnviaTrackMobile',
  data () {
    return {
      formEnviaTrackMobile: 0,
      form: {},
      fields: [],
      isScanning: false,
      isScanningZxing: false,
      codeReader: null,
      errorMessage: '',
      result: null,
      hints: null,
      videoInputDevices: [],
      selectedDeviceIndex: 0,
      videoInputDeviceId: null,
      labelCamera: '',
      selectedCamera: {},
      files: [],
      keysBackCamera: ['back', 'rear', 'environment', 'trasera', 'posterior', 'exterior'],
      keysfrontCamera: ['front', 'selfie', 'user', 'face', 'facetime', 'frontal', 'cam', 'delantera', 'frente', 'cara', 'selfi'],
      photos: [],
      maxPhotos: 3
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'orderNumber1', disabled: false, label: 'Nº de Orden Quagga', containerClass: 'col-6'},
        {name: 'line0', useSlot: true, skipLine: true, containerClass: 'col-12' },
        { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'orderNumber2', disabled: false, label: 'Nº de Orden Zxing', containerClass: 'col-6'},
        {name: 'line0', useSlot: true, skipLine: true, containerClass: 'col-12' }
      ]
    },
    startScannerQuagga() {
      this.isScanning = true
      this.$nextTick(() => {
        Quagga.init({
          inputStream: {
            type: 'LiveStream',
            target: document.querySelector('#scanner-container'),
            constraints: {
              width: 640,
              height: 480,
              facingMode: 'environment'
            }
          },
          decoder: {
            readers: ['code_128_reader'] // Puedes agregar otros lectores si es necesario
          }
        }, (err) => {
          if (err) {
            this.isScanning = false
            return
          }
          Quagga.start()
        })

        Quagga.onDetected(this.onDetected)
      })
    },
    onDetected(result) {
      this.form.orderNumber1 = result.codeResult.code
      this.formEnviaTrackMobile++
      this.stopScanner()
    },
    stopScanner() {
      Quagga.stop()
      this.isScanning = false
    },
    // ZXING
    initScanner() {
      this.isScanningZxing = true
      this.hints = new Map()
      this.getListCameras(this.keysBackCamera)
    },
    getListCameras(keysCameras) {
      this.codeReader = new BrowserMultiFormatReader()
      this.codeReader
        .listVideoInputDevices()
        .then((videoInputDevices) => {
          this.videoInputDevices = videoInputDevices
          this.findRearCameraIndex(keysCameras)
          this.decodeFromSelectedDevice()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    decodeFromSelectedDevice() {
      this.codeReader.decodeFromVideoDevice(this.selectedCamera.deviceId, this.$refs.video, this.onDecodeResult, this.hints)
    },
    toggleCamera() {
      this.stopScannerZxing()
      if (this.keysBackCamera.some(keyword => this.selectedCamera.label.toLowerCase().includes(keyword))) {
        this.getListCameras(this.keysfrontCamera)
      } else {
        this.getListCameras(this.keysBackCamera)
      }
      this.decodeFromSelectedDevice()
      this.isScanningZxing = true
    },
    onDecodeResult(result, err) {
      if (result) {
        this.result = result.text
        this.form.orderNumber2 = result.text
        this.formEnviaTrackMobile++
        this.stopScannerZxing()
      }
      if (err && !(err instanceof NotFoundException)) {
        console.error(err)
      }
    },
    stopScannerZxing() {
      this.isScanningZxing = false
      if (this.codeReader) {
        this.codeReader.reset()
        this.stopVideoStream()
      }
    },
    stopVideoStream() {
      const videoElement = this.$refs.video
      const stream = videoElement.srcObject
      if (stream) {
        const tracks = stream.getTracks()
        tracks.forEach(track => track.stop())
        videoElement.srcObject = null
      }
    },
    findRearCameraIndex(keysCameras) {
      const camera = this.videoInputDevices.find(device => {
        return keysCameras.some(keyword => device.label.toLowerCase().includes(keyword))
      })
      this.labelCamera = camera?.label.toLowerCase() || this.videoInputDevices[0]?.label.toLowerCase()
      if (camera) {
        this.selectedCamera =  camera
      } else {
        this.selectedCamera =  this.videoInputDevices[0]
      }
    },
    uploadFiles (e) {
      this.files = [...e.target.files]
    },
    deleteFile(index) {
      this.files = this.files?.filter((e, i) => i !== index)
    },
    initCamera() {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.$refs.videoEvidence.srcObject = stream
        })
        .catch((error) => {
          console.error('Error al acceder a la cámara: ', error)
        })
    },
    capturePhoto() {
      if (this.photos.length < this.maxPhotos) {
        const canvas = this.$refs.canvas
        const videoEvidence = this.$refs.videoEvidence
        canvas.width = videoEvidence.videoWidth
        canvas.height = videoEvidence.videoHeight
        console.log(canvas.width, canvas.height)
        const context = canvas.getContext('2d')
        console.log('context', context)
        context.drawImage(videoEvidence, 0, 0, canvas.width, canvas.height)
        console.log('videoEvidence', videoEvidence)
        this.photos.push(canvas.toDataURL('image/png'))
        this.stopCamera()
      }
    },

    stopCamera() {
      if (this.$refs.videoEvidence?.srcObject) {
        this.$refs.videoEvidence.srcObject.getTracks().forEach((track) => {
          track.stop() // Detiene cada pista de video
        })
        this.$refs.videoEvidence.srcObject = null
      }
    }
  },
  beforeDestroy() {
    if (this.isScanning) {
      this.stopScanner()
    }
  }
}
</script>
<style scoped>
  .scanner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    z-index: 1000;
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .controls {
    position: absolute;
    top: 10px;
    right: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .toggle-camera-button,
  .stop-scanner-button {
    background: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
  }

  .toggle-camera-button i,
  .stop-scanner-button i {
    font-size: 20px;
  }
  .stop-scanner-button i {
    color: red;
  }
  video {
    width: 100%;
    height: auto;
  }
  img {
    margin-top: 10px;
    width: 100px;
    height: auto;
  }
</style>
